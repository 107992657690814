import { BuildingOffice2Icon, EnvelopeIcon, PhoneIcon } from '@heroicons/react/24/outline';
import { useEffect, useState } from 'react';

import PricingEstimationPlan from './PricingEstimationPlan';

const planOptions = [
  { name: 'Discovery', inStock: true },
  { name: 'Standard', inStock: true },
  { name: 'Enterprise', inStock: true },
];

const mapPlanToPrice = (plan?: string) => {
  if (!plan) return -1;
  if (plan === 'discovery') return 0;
  if (plan === 'standard') return 150;
  if (plan === 'enterprise') return 250;

  return -1;
};

export default function PricingEstimation() {
  const [selectedPlan, setSelectedPlan] = useState(planOptions[1]);
  const [documentsCount, setDocumentsCount] = useState(20);
  const [testCasesCount, setTestCasesCount] = useState(80);
  const [automatedTestsCount, setAutomatedTestsCount] = useState(80);
  const [estimation, setEstimation] = useState(150 + 30 * 2);

  useEffect(() => {
    const selectedPrice: number = mapPlanToPrice(selectedPlan?.name.toLowerCase());
    const documentPrice = 1.2;
    const testCasePrice = 0.4;
    const automatedTestPrice = 0.8;
    setEstimation(
      selectedPrice +
        documentsCount * documentPrice +
        testCasesCount * testCasePrice +
        automatedTestsCount * automatedTestPrice
    );
  }, [selectedPlan, documentsCount, testCasesCount, automatedTestsCount]);

  return (
    <div className="relative isolate bg-gray-900" id="price-estimation">
      <div className="mx-auto grid max-w-7xl grid-cols-1 lg:grid-cols-2">
        <div className="relative px-6 pb-20 pt-24 sm:pt-32 lg:static lg:px-8 lg:py-48">
          <div className="mx-auto max-w-xl lg:mx-0 lg:max-w-lg">
            {/* <div className="absolute inset-y-0 left-0 -z-10 w-full overflow-hidden ring-1 ring-white/5 lg:w-1/2">
              <svg
                className="absolute inset-0 h-full w-full stroke-gray-700 [mask-image:radial-gradient(100%_100%_at_top_right,white,transparent)]"
                aria-hidden="true"
              >
                <defs>
                  <pattern
                    id="54f88622-e7f8-4f1d-aaf9-c2f5e46dd1f2"
                    width={200}
                    height={200}
                    x="100%"
                    y={-1}
                    patternUnits="userSpaceOnUse"
                  >
                    <path d="M130 200V.5M.5 .5H200" fill="none" />
                  </pattern>
                </defs>
                <svg x="100%" y={-1} className="overflow-visible fill-gray-800/20">
                  <path d="M-470.5 0h201v201h-201Z" strokeWidth={0} />
                </svg>
                <rect
                  width="100%"
                  height="100%"
                  strokeWidth={0}
                  fill="url(#54f88622-e7f8-4f1d-aaf9-c2f5e46dd1f2)"
                />
              </svg>
              <div
                className="absolute -left-56 top-[calc(100%-13rem)] transform-gpu blur-3xl lg:left-[max(-14rem,calc(100%-59rem))] lg:top-[calc(50%-7rem)]"
                aria-hidden="true"
              >
                <div
                  className="aspect-[1155/678] w-[72.1875rem] bg-gradient-to-br from-[#80caff] to-[#4f46e5] opacity-20"
                  style={{
                    clipPath:
                      'polygon(74.1% 56.1%, 100% 38.6%, 97.5% 73.3%, 85.5% 100%, 80.7% 98.2%, 72.5% 67.7%, 60.2% 37.8%, 52.4% 32.2%, 47.5% 41.9%, 45.2% 65.8%, 27.5% 23.5%, 0.1% 35.4%, 17.9% 0.1%, 27.6% 23.5%, 76.1% 2.6%, 74.1% 56.1%)',
                  }}
                />
              </div>
            </div> */}
            <h2 className="text-base font-semibold leading-7 text-indigo-400">No hidden cost</h2>
            <h2 className="text-3xl font-bold tracking-tight text-white">Estimate your billing</h2>
            <p className="mt-6 text-lg leading-8 text-gray-300">
              Transparency is one of our core values, at OpenTestCase. We won't play usual billing tricks on you. You
              can estimate your billing today before signing up with us.
            </p>
            <p className="mt-6 text-lg leading-8 text-gray-300">
              Our plans are cheap for a reason. We believe it's more fair for you to pay for what you use. The more
              "intelligent" actions you perform, such as reviewing a user stories or generating test cases, the more
              you'll pay. But if you lower your usage for a month, you won't pay a massive bill for nothing.
            </p>
            <p className="mt-16 text-6xl font-bold tracking-tight text-white">
              ${estimation} <span className="text-2xl"> / month</span>
            </p>
          </div>
        </div>
        <form action="#" method="POST" className="px-6 pb-24 pt-20 sm:pb-32 lg:px-8 lg:py-48">
          <div className="mx-auto max-w-xl lg:mr-0 lg:max-w-lg">
            <div className="grid grid-cols-1 gap-x-8 gap-y-6 sm:grid-cols-2">
              <div className="sm:col-span-2">
                <label htmlFor="plan" className="block text-sm font-semibold leading-6 text-white">
                  Plan
                </label>
                <PricingEstimationPlan
                  setSelectedPlan={setSelectedPlan}
                  planOptions={planOptions}
                  selectedPlan={selectedPlan}
                />
              </div>
              <div className="sm:col-span-2">
                <label htmlFor="documents-count" className="block text-sm font-semibold leading-6 text-white">
                  Number of documents
                </label>
                <span className="block text-xs text-slate-300">
                  Get quality score and tips to improve your documents
                </span>
                <div className="mt-2.5">
                  <input
                    type="number"
                    name="documents-count"
                    id="documents-count"
                    value={documentsCount}
                    onChange={(e) => setDocumentsCount(Number(e.target.value))}
                    autoComplete="30"
                    className="block w-full rounded-md border-0 bg-slate-900 px-3.5 py-2 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6"
                  />
                </div>
              </div>
              <div className="sm:col-span-2">
                <label htmlFor="documents-count" className="block text-sm font-semibold leading-6 text-white">
                  Number of test cases
                </label>
                <span className="block text-xs text-slate-300">
                  Considering an average of 4 test cases per documents
                </span>
                <div className="mt-2.5">
                  <input
                    type="number"
                    name="documents-count"
                    id="documents-count"
                    value={testCasesCount}
                    onChange={(e) => setTestCasesCount(Number(e.target.value))}
                    autoComplete="30"
                    className="block w-full rounded-md border-0 bg-slate-900 px-3.5 py-2 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6"
                  />
                </div>
              </div>
              <div className="sm:col-span-2">
                <label htmlFor="documents-count" className="block text-sm font-semibold leading-6 text-white">
                  Number of automated tests
                </label>
                <span className="block text-xs text-slate-300">
                  Up to you to automate all or part of the generated test
                </span>
                <div className="mt-2.5">
                  <input
                    type="number"
                    name="documents-count"
                    id="documents-count"
                    value={automatedTestsCount}
                    onChange={(e) => setAutomatedTestsCount(Number(e.target.value))}
                    autoComplete="30"
                    className="block w-full rounded-md border-0 bg-slate-900 px-3.5 py-2 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6"
                  />
                </div>
              </div>
            </div>
            <div className="mt-8 flex justify-end">
              <button
                type="submit"
                className="rounded-md bg-indigo-500 px-3.5 py-2.5 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500"
              >
                Get started
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}
